<!-- Finder expansible de Expedientes Ciasfun -->

<template>
  <div class="exp_M_ciasfun_XM" v-if="schema">      
    <v-sheet :elevation="4">

      <!-- Botones Mto -->
      <div class="pt-2 d-flex justify-left">
        <!-- Mto -->            
        <btramto          
          :btra_permisos="schema.btra"
          :btra_particular="Entorno.btra"
          btra_modulo="xm"
          :estado="estado"
          @onEvent="event_btra_Mto">                
        </btramto>     
      </div>

      <!-- Controles del Mto -->
      <div class="contenedor">   
        <v-row no-gutters>
          <v-text-field
            style="flex: 0 0 40%"
            v-bind="$input"
            v-model="schema.ctrls.contacto.value"
            :label="schema.ctrls.contacto.label"
            :disabled="!is_edit">                
          </v-text-field>

          <v-text-field
            style="flex: 0 0 30%"
            v-bind="$input"
            v-model="schema.ctrls.ref.value"
            :label="schema.ctrls.ref.label"
            :disabled="!is_edit">                
          </v-text-field>

          <compfecha
            style="flex: 0 0 30%"
            :schema="schema.ctrls.fecha"
            :edicion="is_edit">                
          </compfecha>
        </v-row>

        <v-row no-gutters>
          <v-select
            style="flex: 0 0 25%"
            v-bind="$select"
            v-model="schema.ctrls.emp_tp.value"
            :label="schema.ctrls.emp_tp.label"
            :items="itemsEmp"
            :disabled="!is_edit"
            item-value="id"
            item-text="name"
            @change="change_empresa()">         
          </v-select>

          <ctrlfinder
            style="flex: 0 0 75%"
            :finderName="schema.ctrls.emp.comp.finder"
            :schema="get_schema_emp"
            :edicion="is_edit">
          </ctrlfinder>
        </v-row>

        <v-row no-gutters>
          <v-text-field
            v-bind="$input"
            v-model="schema.ctrls.tlf.value"
            :label="schema.ctrls.tlf.label"
            :disabled="!is_edit">                    
          </v-text-field>

          <v-text-field
            v-bind="$input"
            v-model="schema.ctrls.fax.value"
            :label="schema.ctrls.fax.label"
            :disabled="!is_edit">                    
          </v-text-field>

          <v-text-field
            v-bind="$input"
            v-model="schema.ctrls.email.value"
            :label="schema.ctrls.email.label"
            :disabled="!is_edit">                
          </v-text-field>
        </v-row>

        <v-row no-gutters>
          <v-col md="12">
            <ctrlobs3            
              v-if="record"            
              :schemaComponente="schema.ctrls.obs"
              :schema="schema"
              :record="record"
              :edicion="is_edit">
            </ctrlobs3>   
          </v-col>      
        </v-row>
      </div>
    </v-sheet>       
  </div>
</template>



<script>

  import { mixinMto } from "@/mixins/mixinMto.js";  
  import plugs from "@/common/general_plugs";  
  const btramto = () => plugs.groute("btramto.vue", "comp");  
  const ctrlfinder = () => plugs.groute("ctrlfinder.vue", "comp");  
  const compfecha = () => plugs.groute("compfecha.vue", "comp");
  const ctrlobs3 = () => plugs.groute("ctrlobs3.vue", "comp");
  
  export default {
    mixins: [mixinMto],
    components: { btramto, ctrlfinder, compfecha, ctrlobs3 },
    props: {
      padre: { type:String, default: '' },    
      accion: { type:[Number, String], default: ''},
      accionRow: { type:Object, default: ()=> {}}, 
      disparo: { type:Boolean, default:false }, 
      componenteTipo: { type:String, default:'M' },
      Entorno: { type: Object, default: function() { return JSON.parse(JSON.stringify(this.$cfg.base.M)); }}
    },

    created() {
      console.log('Record recibido como prop::: ', this.record);
    },

    data() {
      return {
        schema: null,
        api:'exp_Ciasfun_M',
        stName:'stMexp_Ciasfun_M',
        disparoFinder:false,
        
        // Pendiente: ver donde ponemos campo_relacionado
        campo_relacionado: "id_exp",
        itemsEmp:[]        
      };
    },  

    computed: {
      // devuelvo array con los medios de transporte
      get_medio() {
        return [
          { id: "0", name: "TERRESTRE" },
          { id: "1", name: "AEREO" },
          { id: "2", name: "MARÍTIMO" }
        ];
      },

      get_schema_emp() {
        console.log("dev ********************** exp_M_ciasfun_XM - get_schema_callemp");
        
        let emp = this.schema.ctrls.emp;
        let tipoEmp = this.$store.state[emp.comp.source];
        let tipo = Number(this.schema.ctrls[emp.comp.f2]["value"]) - 1;
        
        // guardo el nombre del ctrlfinder a mostrar
        emp.comp.finder = tipoEmp[tipo < 0 ? 0 : tipo].finder;        
        return emp;
      },


    },

    methods: {
      ini_data() { 
        console.log("DEV " + this.api + " ********************** (ini_data)");

        // guardo del store en variables locales
        this.schema = this.$store.state[this.stName].schema;  
        
        // entorno        
        this.Entorno.header.header= false;    
        this.Entorno.btra="XM";   

        //array tipo de Compañía
        this.itemsEmp=[
          { id: "1", name: "Funeraria sucursal",finder:"funes_F" },
          { id: "2", name: "Compañia sucursal", finder:"cias_F" },
          { id: "3", name: "Otros (Miscelanea)",finder:"misc_F" },
        ]
      },


      // al cambiar el tipo de cia, reseteo datos de Empresa
      change_empresa() {
        this.schema.ctrls.emp.value= "";
        this.schema.ctrls.emp.comp.valueaux= "";
      } ,
    }
};
</script>
